import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

const useUsers = defaultValue => {
  const [users, setUsers] = useState(defaultValue)

  useEffect(() => {
    GET('users').then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.body !== null) {
        setUsers(res.body.filter(item => item.id > 1))
      }
    })
  }, [])

  return [users, setUsers]
}

export default useUsers
